import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import CheckIcon from '../icons/CheckIcon'

export interface SquareChoiceOptionProps {
    checked?: boolean
    label: string
    checkbox?: boolean
    onClick?: () => void
    image: string
    style?: CSSProperties
}

export const SquareChoiceOption: React.FC<SquareChoiceOptionProps> = ({
    checked = false,
    label,
    checkbox,
    image,
    style,
    onClick,
}) => {
    // const wrapperStyles = classNames(
    //     'flex flex-row-reverse sm:flex-col px-8 py-2 sm:p-6 w-full sm:w-5/12 justify-between mb-4 mx-2 shadow-standard rounded-lg cursor-pointer items-center animate-fade-in-left border-2 border-transparent',
    //     checked && 'bg-boost6 border-2 border-boost2',
    // )

    const wrapperStyles = classNames(
        'flex flex-col px-2 py-4 justify-between shadow-standard rounded-lg cursor-pointer items-center animate-fade-in-left border-2 border-transparent',
        checked && 'bg-boost6 border-2 border-boost2',
    )
    // const imageStyles = classNames('h-10 sm:h-20')
    const imageStyles = classNames('h-20 px-4')
    // const textStyles = classNames('font-bold text-center sm:pt-4')
    const textStyles = classNames('font-bold text-center pt-2')
    const checkboxStyles = classNames(
        // 'w-6 h-6 flex justify-center items-center text-white font-bold sm:mt-4',
        'w-6 h-6 flex justify-center items-center text-white font-bold mt-4',
        checked ? 'bg-boost2' : 'bg-white border-2 border-checkboxborder',
        checkbox ? 'rounded-lg' : 'rounded-full',
    )

    return (
        <div onClick={onClick} style={style} className={wrapperStyles}>
            <img className={imageStyles} src={image} />
            <div className={textStyles}>{label}</div>
            {checkbox && <div className={checkboxStyles}>{checked && <CheckIcon className="w-5 h-5" />}</div>}
        </div>
    )
}
