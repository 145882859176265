import { trackAmplitudeAndGTAGEvent } from '@services/client/events'
import { fbAuth } from '@services/client/firebaseClient'
import { useState } from 'react'
import * as Sentry from '@sentry/nextjs'
import { useTranslation } from '../i18n'
import { signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from 'firebase/auth'

export const useSignInWithEmailAndPassword = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [success, setSuccess] = useState(false)

    const onSignInWithEmailAndPassword = async (email: string, password: string) => {
        setSuccess(false)
        setError(null)
        setLoading(true)

        trackAmplitudeAndGTAGEvent('login_with_provider', {
            provider: 'password',
            source: 'sign_in',
        })

        try {
            await signInWithEmailAndPassword(fbAuth, email, password)
            setSuccess(true)
        } catch (_err) {
            setSuccess(false)
            trackAmplitudeAndGTAGEvent('login_with_provider_failed', {
                provider: 'password',
                code: _err.code,
                source: 'sign_in',
            })

            if (_err.code === 'auth/wrong-password') {
                setError(t('common:errors.password.wrong'))
            } else if (_err.code === 'auth/user-not-found') {
                setError(t('common:errors.user.does_not_exist'))
            } else {
                Sentry.captureException(_err)
                setError(t('common:errors.unknown'))
            }
        } finally {
            setLoading(false)
        }
    }

    return { onSignInWithEmailAndPassword, success, error, loading }
}

export const useResetPassword = () => {
    const { t } = useTranslation()
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    async function resetPassword(email: string) {
        setLoading(true)
        setError(null)
        try {
            await sendPasswordResetEmail(fbAuth, email)
            setSuccess(true)
            setError(null)
        } catch (_err) {
            setSuccess(false)
            if (_err.code === 'auth/user-not-found') {
                setError(t('common:errors.user.does_not_exist'))
            } else {
                Sentry.captureException(_err)
                setError(t('common:errors.unknown'))
            }
        } finally {
            setLoading(false)
        }
    }
    return { resetPassword, loading, error, success }
}

export const useSignOut = () => {
    async function onSignOut() {
        try {
            await signOut(fbAuth)
        } catch (_err) {
            Sentry.captureException(_err)
        }
    }

    return { onSignOut }
}
