import { Step } from '../../common/Step'
import SignUpForm from './SignUpForm'
import { useQuestion, useSurvey } from '@contexts/survey'
import { trackAmplitudeAndGTAGEvent } from '@services/client/events'
import { useTranslation } from '../../../i18n'
import { LockIcon } from '@components/common/LockIcon'
import { Button } from '@components/common/Button'
import { TextInput } from '@components/common/TextInput'
import { useEmailStep } from './useEmailStep'
import UserLoginPrompt from './UserLoginPrompt'
import { useExperiment } from 'statsig-react'

export const EmailStep = () => {
    const [question] = useQuestion()
    const { t } = useTranslation()
    const [_, dispatch] = useSurvey()

    const emailStepVariant = useExperiment('email_step_variant').config.getValue('variant', 'forced') as
        | 'skippable'
        | 'forced'

    const onSuccess = () => {
        dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: 'email', value: form.email })
        dispatch({ type: 'QUESTION_COMPLETED' })
    }

    const onSkip = () => {
        trackAmplitudeAndGTAGEvent('email_skipped')
        dispatch({ type: 'QUESTION_COMPLETED' })
    }

    const {
        processing,
        form,
        handleEmailSubmit,
        onEmailChange,
        promptUserLogin,
        updateError,
        setProcessing,
        setPromptUserLogin,
        onSignIn,
    } = useEmailStep({ onSuccessFinalAction: onSuccess, origin: 'page', emailOnly: true })

    return (
        <Step key={question.path} name={question.name}>
            <TextInput
                type="email"
                label={t('survey:signup.your_email')}
                note={<LockIcon className="w-4 h-4" />}
                value={form.email}
                error={updateError || (form.emailError && t(form.emailError))}
                onChange={onEmailChange}
                disabled={processing}
            />
            <Button
                processing={processing}
                primary
                label={t('survey:signup.request_my_plan')}
                className="w-full"
                onClick={handleEmailSubmit}
            />
            {emailStepVariant === 'skippable' && (
                <div className="mt-2 text-center">
                    <a className="cursor-pointer hover:underline" onClick={onSkip}>
                        {t('survey:signup.continue_as_guest')}
                    </a>
                </div>
            )}
            <div className="text-gray-500 text-xs leading-snug w-full md:leading-normal text-center mt-4 px-4">
                <p
                    dangerouslySetInnerHTML={{
                        __html: t('survey:signup.information_secure', {
                            tos_url: 'https://fastic.com/terms/',
                            privacy_url: 'https://fastic.com/privacy-policy/',
                        }),
                    }}
                ></p>
            </div>

            {promptUserLogin && (
                <UserLoginPrompt
                    email={promptUserLogin.email}
                    provider={promptUserLogin.provider}
                    processing={processing}
                    updateError={updateError}
                    onClose={() => {
                        setProcessing(false)
                        setPromptUserLogin(null)
                    }}
                    onSignIn={onSignIn}
                />
            )}
        </Step>
    )
}
