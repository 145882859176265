import React from 'react'
import { useQuestion, useSurvey } from '../../contexts/survey'
import { useTranslation } from '../../i18n'
import Lottie from 'lottie-react'
import { SurveyStateInitialized } from '@contexts/survey/survey'
import { Title } from '@components/common/Title'

export interface LottieTextStep {
    animation: any
}

const LottieTextStep: React.FC<LottieTextStep> = ({ animation }) => {
    const [question] = useQuestion()
    const { t, i18n } = useTranslation()
    const [surveyState, _] = useSurvey()

    const title = i18n.exists(`survey:${question.name}.title`)
        ? t(`survey:${question.name}.title`, { context: (surveyState as SurveyStateInitialized).theme })
        : null

    const subtitle = i18n.exists(`survey:${question.name}.subtitle`)
        ? t(`survey:${question.name}.subtitle`, {
              context: (surveyState as SurveyStateInitialized).theme,
              interpolation: { escapeValue: false },
          })
        : null

    return (
        <div className="max-w-lg mx-auto px-4">
            <div className="my-8 flex justify-center">
                <Lottie className="w-4/5" animationData={animation} loop={true} />
            </div>
            {title && <Title>{title}</Title>}

            {subtitle && (
                <h3
                    className="text-center text-lg xs:leading-tight text-dark3 mt-4 px-2 xs:px-4"
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                />
            )}
        </div>
    )
}

export default LottieTextStep
