import classNames from 'classnames'
import React from 'react'

const LoginButton: React.FC<{
    icon: React.ReactElement
    text: string
    processing: boolean
    provider: 'facebook' | 'google'
    onClick: () => void
}> = ({ icon, text, processing, provider, onClick }) => {
    const buttonStyles = classNames(
        'flex w-full flex-row justify-center items-center rounded-3xl border border-light py-4 px-4 focus:outline-none font-bold focus:shadow-outline',
        provider === 'facebook' && 'text-white bg-facebook',
        provider === 'google' && 'text-dark1',
        processing && 'pointer-events-none opacity-80',
    )

    return (
        <button className={buttonStyles} type="button" disabled={processing} onClick={onClick}>
            <span className="pr-2">{icon}</span>
            <span>{text}</span>
        </button>
    )
}

export default LoginButton
