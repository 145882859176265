import classNames from 'classnames'
import CrossIcon from '../icons/CrossIcon'
import Portal from './Portal'

export const Modal: React.FC<React.PropsWithChildren<{ onClose?: () => void; mw?: string }>> = props => {
    const { onClose, mw, children } = props

    const containerClass = classNames(
        'relative inline-block bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-10 align-middle w-full animate-fade-in',
        mw ? mw : 'max-w-lg',
    )

    return (
        <Portal>
            <div className="fixed z-50 inset-0 overflow-y-auto">
                <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center block sm:p-0">
                    <div className="fixed inset-0 transition-opacity animate-fade-in" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                    <span className="inline-block align-middle h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <div className={containerClass} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        {onClose && (
                            <div className="absolute top-0 right-0 pt-4 pr-4 flex">
                                <button
                                    className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white"
                                    onClick={onClose}
                                >
                                    <CrossIcon />
                                </button>
                            </div>
                        )}

                        <div className="bg-white pt-10 pb-6 md:pt-6 px-6">{children}</div>
                    </div>
                </div>
            </div>
        </Portal>
    )
}
