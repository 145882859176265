import React from 'react'

function FacebookIconFn(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.6666 20H2.70126V10.1414H0V6.9316H2.70116V4.64762C2.70116 1.9411 3.89588 0 7.85048 0C8.68688 0 10 0.168134 10 0.168134V3.14858H8.6208C7.2155 3.14858 6.66681 3.5749 6.66681 4.75352V6.9316H9.94738L9.65525 10.1414H6.6667L6.6666 20Z"
                fill="white"
            />
        </svg>
    )
}

const FacebookIcon = React.memo(FacebookIconFn)
export default FacebookIcon
