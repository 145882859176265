import React, { useEffect, useState } from 'react'
import { Step } from '../common/Step'
import { TextInput } from '../common/TextInput'
import { useQuestion } from '../../contexts/survey'
import { useTranslation } from '../../i18n'
import { formatNumber } from '../../utils/convert'
import { validateAge } from '../../utils/validation'

const AgeStep: React.FC = () => {
    const [question, answers, dispatch] = useQuestion()
    const { t } = useTranslation()

    const [form, setForm] = useState<{
        age?: number
        error?: string
    }>({
        age: answers['age'] as number,
        error: answers['age'] && validateAge(answers['age'] as number),
    })

    useEffect(() => {
        dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: 'age', value: form.age })
    }, [form.age])

    const onAgeChange = (value: string) => {
        const age = formatNumber(value)
        setForm({
            ...form,
            error: null,
            age,
        })
    }

    return (
        <Step key={question.path} name={question.name}>
            <TextInput
                type="tel"
                label={t('survey:age.input_label')}
                value={form.age && form.age.toString()}
                error={form.error && t(form.error)}
                onBlur={() => setForm({ ...form, error: validateAge(form.age) })}
                onChange={onAgeChange}
            />
        </Step>
    )
}

export default AgeStep
