import CheckIcon from '@components/icons/CheckIcon'
import classNames from 'classnames'
import React from 'react'

export interface StepsProgressProps {
    steps: number
    current?: number
    progress?: number
}

export const MilestoneStepsProgress: React.FC<StepsProgressProps> = ({
    steps,
    current = 0,
    progress = 0,
    ...props
}) => {
    return (
        <div className="flex bg-gray-100 rounded-xl ml-1 w-full" style={{ width: 'calc(100% - 33px)' }} {...props}>
            {[...Array(steps)].map((_, index) => {
                return (
                    <div key={`progress_${index}`} className="h-1.5 relative w-[33%]">
                        {index === 0 && <StepProgressCircle first completed />}
                        <StepProgressBar progress={current > index ? 100 : current == index ? progress * 100 : 0} />
                        <StepProgressCircle completed={current > index} />
                    </div>
                )
            })}
        </div>
    )
}

interface StepProgressCircleProps {
    first?: boolean
    label?: string
    completed?: boolean
}

const StepProgressCircle: React.FC<StepProgressCircleProps> = ({ first, label, completed, ...props }) => {
    return (
        <div
            className={classNames(
                'z-10 absolute top-2/4 rounded-full flex justify-center items-center font-bold transform -translate-y-2/4 transition-color duration-300 ease-in-out w-5 h-5',
                completed ? 'bg-yellow-500 text-white' : ' bg-gray-100 text-gray-500',
                first ? 'right-full translate-x-2/4' : 'left-full -translate-x-2/4',
            )}
            {...props}
        >
            {!first && completed && <CheckIcon className="w-4 h-4" />}
        </div>
    )
}

interface StepProgressBarProps {
    progress?: number
}

const StepProgressBar: React.FC<StepProgressBarProps> = ({ progress = 0, ...props }) => {
    return (
        <div
            className="transition-width duration-500 ease-in-out w-0 h-full bg-yellow-500 rounded-xl"
            style={{ width: `${progress}%` }}
            {...props}
        />
    )
}
