import { useState } from 'react'
import { useUser } from '../../../contexts/auth'
import { useQuestion } from '../../../contexts/survey'
import { trackAmplitudeEvent } from '../../../services/client/amplitude'
import { updateUser } from '../../../services/client/api'
import { trackAmplitudeAndGTAGEvent, trackLead } from '@services/client/events'
import { fbAuth } from '../../../services/client/firebaseClient'
import { validateEmail, validateName, validatePassword } from '../../../utils/validation'
import * as Sentry from '@sentry/nextjs'
import { getFBPCookies, sendFacebookEvent } from '@services/client/tracking'
import { useSignInWithEmailAndPassword } from 'hooks/sign-in'
import { useTranslation } from '../../../i18n'
import { useRefetechUser } from '@contexts/auth/auth'
import {
    AuthProvider,
    EmailAuthProvider,
    linkWithCredential,
    fetchSignInMethodsForEmail,
    linkWithPopup,
    OAuthCredential,
    signInWithCredential,
    FacebookAuthProvider,
    GoogleAuthProvider,
    signInWithPopup,
} from 'firebase/auth'

type UserLoginPromt = {
    provider: string
    email: string
}

export type SignUpEventOrigin = 'page' | 'modal'

export function useEmailStep({
    onSuccessFinalAction,
    origin,
    emailOnly,
}: {
    onSuccessFinalAction?: () => void
    origin: SignUpEventOrigin
    emailOnly?: boolean
}) {
    const { t } = useTranslation()
    const [question, answers, dispatch] = useQuestion()
    const user = useUser()
    const refetechUser = useRefetechUser()
    const [processing, setProcessing] = useState(false)
    const [signUpLoading, setSignUpLoading] = useState(false)
    const [promptUserLogin, setPromptUserLogin] = useState<UserLoginPromt | null>(null)
    const [updateError, setUpdateError] = useState<string | null>(null)
    const { onSignInWithEmailAndPassword, error: signInFormError } = useSignInWithEmailAndPassword()

    const [form, setForm] = useState({
        name: '',
        nameError: null,
        email: '',
        emailError: null,
        password: '',
        passwordError: null,
    })

    const onEmailChange = (value: string) => {
        setForm({
            ...form,
            email: value.trim(),
            emailError: null,
        })
    }

    const onNameChange = (value: string) => {
        setForm({
            ...form,
            name: value.trim(),
            nameError: null,
        })
    }

    const onPasswordChange = (value: string) => {
        setForm({
            ...form,
            password: value.trim(),
            passwordError: null,
        })
    }

    const handleEmailSubmit = async () => {
        const emailError = validateEmail(form.email)
        if (emailError) {
            setForm({
                ...form,
                emailError,
            })
        } else {
            trackAmplitudeAndGTAGEvent('login_with_provider', {
                provider: 'password',
                source: 'link',
                origin,
            })
            await onUpdateUser({ email: form.email }, 'password', 'email', origin)
        }
    }

    const handleFormSubmit = async () => {
        const emailError = validateEmail(form.email)
        const passwordError = emailOnly ? null : validatePassword(form.password)
        const nameError = emailOnly ? null : validateName(form.name)
        if (emailError || passwordError || nameError) {
            setForm({
                ...form,
                emailError,
                passwordError,
                nameError,
            })
        } else {
            await signUpWithEmailAndPassword(form.email, form.password, { userName: form.name })
        }
    }

    const signUpWithEmailAndPassword = async (email: string, password: string, options?: { userName?: string }) => {
        const name = options?.userName ?? null
        trackAmplitudeAndGTAGEvent('login_with_provider', {
            provider: 'password',
            source: 'sign_in',
            origin,
        })

        setUpdateError(null)
        setProcessing(true)
        setSignUpLoading(true)

        try {
            const emailAndPasswordCredential = EmailAuthProvider.credential(email, password)
            await linkWithCredential(fbAuth.currentUser, emailAndPasswordCredential)
            await onUpdateUser({ email, name }, 'password', 'sign_in', origin)
        } catch (error) {
            trackAmplitudeAndGTAGEvent('login_with_provider_failed', {
                provider: 'password',
                code: error.code,
                source: 'sign_in',
                origin,
            })
            if (error.code === 'auth/email-already-in-use') {
                const methods = await fetchSignInMethodsForEmail(fbAuth, email)
                if (methods[0] === 'password') {
                    await onSignInWithEmailAndPassword(email, password)
                    await onUpdateUser({ email, name }, 'password', 'sign_in', origin)
                } else {
                    setPromptUserLogin({
                        email: email,
                        provider: methods[0],
                    })
                }
            } else if (error.code === 'auth/wrong-password') {
                setUpdateError(t('common:errors.password.wrong'))
            } else {
                Sentry.captureException(error)
                setUpdateError(t('common:errors.unknown'))
            }
        }
        setSignUpLoading(false)
        setProcessing(false)
    }

    const onUpdateUser = async (
        userData: { email: string; name?: string },
        providerId: string,
        source: string,
        origin: SignUpEventOrigin,
    ) => {
        const name = userData.name ?? user.name
        const email = userData.email
        trackAmplitudeAndGTAGEvent('login_with_provider_succeed', {
            provider: providerId,
            source: source,
            origin,
        })

        setProcessing(true)
        setUpdateError(null)
        const result = await updateUser({ email, name, survey: { answers: answers } })
        if (result.error) {
            trackAmplitudeAndGTAGEvent('update_user_failed', {
                provider: providerId,
                source: source,
                code: result.error.code,
                message: result.error.message,
                origin,
            })
            if (result.error.code === 'auth/email-already-exists') {
                const methods = await fetchSignInMethodsForEmail(fbAuth, email)
                setPromptUserLogin({
                    email: email,
                    provider: methods[0],
                })
            } else {
                setUpdateError(result.error.code === 'unknown' ? t('common:errors.unknown') : result.error.message)
            }
            setProcessing(false)
        } else {
            setPromptUserLogin(null)
            trackLead({ uid: user.id, email: user.email })
            const fbCookies = getFBPCookies()
            const fbEventParams = {
                email: user.email,
                userID: user.id,
                event: 'Lead',
                _fbc: fbCookies._fbc,
                _fbp: fbCookies._fbp,
                source: '/survey/your_email',
            }
            sendFacebookEvent(fbEventParams)
            trackAmplitudeEvent('lead_created', {
                provider: providerId,
                source: source,
                origin,
            })
            await refetechUser()
            onSuccessFinalAction && onSuccessFinalAction()
        }
    }

    const onLinkWithOAuthProvider = async (provider: AuthProvider) => {
        trackAmplitudeAndGTAGEvent('login_with_provider', {
            provider: provider.providerId,
            source: 'link',
            origin,
        })

        setProcessing(true)
        setUpdateError(null)

        try {
            const result = await linkWithPopup(fbAuth.currentUser, provider)
            await onUpdateUser({ email: result.user.email }, provider.providerId, 'link', origin)
        } catch (linkError) {
            trackAmplitudeAndGTAGEvent('login_with_provider_failed', {
                provider: provider.providerId,
                code: linkError.code,
                source: 'link',
                origin,
            })
            Sentry.captureException(linkError)

            const email: string = linkError.email
            if (linkError.code === 'auth/provider-already-linked') {
                await onUpdateUser({ email: email }, provider.providerId, 'link', origin)
            } else if (linkError.code === 'auth/credential-already-in-use') {
                const linkCredentials: OAuthCredential = linkError.credential
                try {
                    trackAmplitudeAndGTAGEvent('login_with_provider', {
                        provider: provider.providerId,
                        source: 'credential_in_use',
                        origin,
                    })
                    const signInResult = await signInWithCredential(fbAuth, linkCredentials)
                    await onUpdateUser(
                        { email: signInResult.user.email },
                        provider.providerId,
                        'credential_in_use',
                        origin,
                    )
                } catch (signInError) {
                    trackAmplitudeAndGTAGEvent('login_with_provider_failed', {
                        provider: provider.providerId,
                        code: signInError.code,
                        source: 'credential_in_use',
                        origin,
                    })
                    Sentry.captureException(signInError)
                    setProcessing(false)
                }
            } else if (linkError.code === 'auth/email-already-in-use') {
                const methods = await fetchSignInMethodsForEmail(fbAuth, email)
                if (methods.length === 0) {
                    // The email is just reserved, we continue with password provider
                    await onUpdateUser({ email }, 'password', 'link', origin)
                } else {
                    setProcessing(false)
                    setPromptUserLogin({
                        email: email,
                        provider: methods[0],
                    })
                }
            } else {
                setProcessing(false)
            }
        }
    }

    const onContinueWithFacebook = async () => {
        const provider = new FacebookAuthProvider()
        provider.addScope('email')
        provider.addScope('public_profile')
        await onLinkWithOAuthProvider(provider)
    }

    const onContinueWithGoogle = async () => {
        const provider = new GoogleAuthProvider()
        provider.addScope('email')
        await onLinkWithOAuthProvider(provider)
    }

    // UserLoginPrompt modal logic
    const onSignIn = async (email: string, providerId: string, password?: string) => {
        switch (providerId) {
            case 'facebook.com': {
                const provider = new FacebookAuthProvider()
                provider.addScope('email')
                provider.addScope('public_profile')
                await onSignInWithOAuthProvider(provider)
                break
            }
            case 'google.com': {
                const provider = new GoogleAuthProvider()
                provider.addScope('email')
                await onSignInWithOAuthProvider(provider)
                break
            }
            default: {
                await signUpWithEmailAndPassword(email, password)
                break
            }
        }
    }

    const onSignInWithOAuthProvider = async (provider: AuthProvider) => {
        trackAmplitudeAndGTAGEvent('login_with_provider', {
            provider: provider.providerId,
            source: 'sign_in',
            origin,
        })

        setProcessing(true)
        setUpdateError(null)

        try {
            const result = await signInWithPopup(fbAuth, provider)
            await onUpdateUser({ email: result.user.email }, provider.providerId, 'sign_in', origin)
        } catch (error) {
            trackAmplitudeAndGTAGEvent('login_with_provider_failed', {
                provider: provider.providerId,
                code: error.code,
                source: 'sign_in',
                origin,
            })
            if (error.code !== 'auth/popup-closed-by-user') {
                Sentry.captureException(error)
                setUpdateError(t('common:errors.unknown'))
            }
            setProcessing(false)
        }
    }

    return {
        processing,
        onContinueWithFacebook,
        onContinueWithGoogle,
        form,
        handleEmailSubmit,
        handleFormSubmit,
        signInFormError,
        signUpLoading,
        onNameChange,
        onPasswordChange,
        onEmailChange,
        promptUserLogin,
        updateError,
        setProcessing,
        setPromptUserLogin,
        onSignIn,
        onUpdateUser,
        question,
    }
}
