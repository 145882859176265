import { Button } from '@components/common/Button'
import FacebookIcon from '@components/common/FacebookIcon'
import GoogleIcon from '@components/icons/GoogleIcon'
import { Modal } from '@components/common/Modal'
import { TextInput } from '@components/common/TextInput'
import { Title } from '@components/common/Title'
import { useTranslation } from '../../../i18n'
import React, { useState } from 'react'
import LoginButton from './LoginButton'

const UserLoginPrompt: React.FC<{
    email: string
    provider: string
    processing: boolean
    updateError?: string
    onClose: () => void
    onSignIn: (email: string, provider: string, password?: string) => void
}> = ({ email, provider, processing, updateError, onClose, onSignIn }) => {
    const { t } = useTranslation()

    const [form, setForm] = useState<{
        password?: string
        passwordError?: string
    }>({})

    const onPasswordChange = (value: string) => {
        setForm({
            ...form,
            password: value.trim(),
            passwordError: null,
        })
    }

    const onSignInWithFacebook = async () => {
        onSignIn(email, 'facebook.com')
    }

    const onSignInWithGoogle = async () => {
        onSignIn(email, 'google.com')
    }

    const onSignInWithEmailPassword = async () => {
        onSignIn(email, 'password', form.password)
    }

    return (
        <Modal onClose={onClose}>
            <Title>{t('survey:download.sign_in')}</Title>
            <div className="mt-2 mb-4 text-center text-gray-600">
                <p
                    className="mb-2"
                    dangerouslySetInnerHTML={{
                        __html: t('survey:signup.account_with_email_already_exists', {
                            email,
                        }),
                    }}
                ></p>
            </div>
            {provider === 'password' && (
                <div>
                    <p className="text-center mb-2 text-gray-600">{t('survey:signup.input_password')}</p>
                    <TextInput
                        type="password"
                        label={t('survey:registration.your_password')}
                        value={form.password}
                        error={updateError || (form.passwordError && t(form.passwordError))}
                        onChange={onPasswordChange}
                        disabled={processing}
                    />
                    <Button
                        primary
                        label={t('common:controls.next')}
                        onClick={onSignInWithEmailPassword}
                        className="w-full"
                    />
                </div>
            )}
            {provider === 'facebook.com' && (
                <div className="flex flex-col items-center">
                    <LoginButton
                        icon={<FacebookIcon color="#111827" className="w-4 h-4" />}
                        text={t('survey:signup.continue_with_facebook')}
                        processing={processing}
                        provider="facebook"
                        onClick={onSignInWithFacebook}
                    />
                    {updateError && <div className="text-xs md:text-sm mt-2 text-red-500">{updateError}</div>}
                </div>
            )}
            {provider === 'google.com' && (
                <div className="flex flex-col items-center">
                    <LoginButton
                        provider="google"
                        icon={<GoogleIcon className="w-4 h-4" />}
                        text={t('survey:signup.continue_with_google')}
                        processing={processing}
                        onClick={onSignInWithGoogle}
                    />
                    {updateError && <div className="text-xs md:text-sm mt-2 text-red-500">{updateError}</div>}
                </div>
            )}
        </Modal>
    )
}

export default UserLoginPrompt
